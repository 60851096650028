export enum GENERAL_DETAIL_TYPE {
  CUSTOMER_DETAILS = "Customer Details",
  SUPPLIER_DETAILS = "Supplier Details",
  EMPLOYEE_DETAILS = "Employee Details"
}

export enum ADDRESS_DETAIL_OPT {
  SHIP_TO = "Ship To",
  BILL_TO = "Bill To"
}
