



















































































import { GENERAL_DETAIL_TYPE } from "./enums/option.enum";
import Vue from "vue";
export default Vue.extend({
  name: "GeneralDetails",
  props: ["handleTypeGeneralDetail"],
  data() {
    return {
      plainOptions: [
        {
          value: GENERAL_DETAIL_TYPE.CUSTOMER_DETAILS,
        },
        {
          value: GENERAL_DETAIL_TYPE.SUPPLIER_DETAILS,
        },
        {
          value: GENERAL_DETAIL_TYPE.EMPLOYEE_DETAILS,
        },
      ],
      formRules: {
        type: {
          label: this.$t("lbl_type"),
          name: "type",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        firstName: {
          label: this.$t("lbl_first_name"),
          name: "firstName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "firstName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        lastName: {
          label: this.$t("lbl_last_name"),
          name: "lastName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "lastName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        email: {
          label: this.$t("lbl_email"),
          name: "email",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "email",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
                // {
                //   validator: emailValidator,
                // }
              ],
            },
          ],
        },
        phoneNumber: {
          label: this.$t("lbl_phone_number"),
          name: "phoneNumber",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "phoneNumber",
            {
              rules: [
                // {
                //   validator: phoneValidator,
                // }
              ],
            },
          ],
        },
        mobileNumber: {
          label: this.$t("lbl_mobile_number"),
          name: "mobileNumber",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "mobileNumber",
            {
              rules: [
                // {
                //   validator: phoneValidator,
                // }
              ],
            },
          ],
        },
        faxNumber: {
          label: this.$t("lbl_fax_number"),
          name: "faxNumber",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["faxNumber"],
        },
        taxRegistrationNumber: {
          label: this.$t("lbl_tax_registration_number"),
          name: "taxRegistrationNumber",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "taxRegistrationNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        taxRegistrationName: {
          label: this.$t("lbl_tax_registration_name"),
          name: "taxRegistrationName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "taxRegistrationName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    generateLabel(key: string | GENERAL_DETAIL_TYPE): string {
      if (key === GENERAL_DETAIL_TYPE.CUSTOMER_DETAILS)
        return this.$t("lbl_customer").toString();
      if (key === GENERAL_DETAIL_TYPE.SUPPLIER_DETAILS)
        return this.$t("lbl_supplier").toString();
      if (key === GENERAL_DETAIL_TYPE.EMPLOYEE_DETAILS)
        return this.$t("lbl_employee").toString();
      return key;
    },
    onChangeCheckBox(value) {
      this.handleTypeGeneralDetail(value);
    },
  },
});
