import { render, staticRenderFns } from "./GeneralDetails.vue?vue&type=template&id=3f1b81f0&scoped=true&"
import script from "./GeneralDetails.vue?vue&type=script&lang=ts&"
export * from "./GeneralDetails.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralDetails.vue?vue&type=style&index=0&id=3f1b81f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f1b81f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/GPW/gpw-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f1b81f0')) {
      api.createRecord('3f1b81f0', component.options)
    } else {
      api.reload('3f1b81f0', component.options)
    }
    module.hot.accept("./GeneralDetails.vue?vue&type=template&id=3f1b81f0&scoped=true&", function () {
      api.rerender('3f1b81f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/contact-data/GeneralDetails.vue"
export default component.exports