var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 18, xs: 24, s: 24, md: 24, lg: 18 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.type.label } },
            [
              _c("a-checkbox-group", {
                attrs: {
                  value: this.$store.state.contactStore.valueType,
                  options: _vm.plainOptions
                },
                on: { change: _vm.onChangeCheckBox },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function(ref) {
                      var value = ref.value
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm.generateLabel(value)))
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.firstName.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.firstName.decorator,
                    expression: "formRules.firstName.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.firstName.name,
                  placeholder: _vm.formRules.firstName.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.lastName.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.lastName.decorator,
                    expression: "formRules.lastName.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.lastName.name,
                  placeholder: _vm.formRules.lastName.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.email.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.email.decorator,
                    expression: "formRules.email.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.email.name,
                  placeholder: _vm.formRules.email.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.phoneNumber.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.phoneNumber.decorator,
                    expression: "formRules.phoneNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.phoneNumber.name,
                  placeholder: _vm.formRules.phoneNumber.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.mobileNumber.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.mobileNumber.decorator,
                    expression: "formRules.mobileNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.mobileNumber.name,
                  placeholder: _vm.formRules.mobileNumber.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.faxNumber.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.faxNumber.decorator,
                    expression: "formRules.faxNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.faxNumber.name,
                  placeholder: _vm.formRules.faxNumber.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.taxRegistrationNumber.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxRegistrationNumber.decorator,
                    expression: "formRules.taxRegistrationNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationNumber.name,
                  placeholder: _vm.formRules.taxRegistrationNumber.placeholder
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.taxRegistrationName.label } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxRegistrationName.decorator,
                    expression: "formRules.taxRegistrationName.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationName.name,
                  placeholder: _vm.formRules.taxRegistrationName.placeholder
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }